<template>
  <div
    class="
      home
      flex
      justify-center
      items-center
      font-extrabold
      text-4xl text-purple-600
      max-w-med
      p-8
      text-center
      bg-purple-100
    "
  >
    Welcome to the IMLEC competition analyzer tool!
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
